import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { FaGoodreads, FaImdb, FaGlobe } from 'react-icons/fa';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../sass/main.scss';



const FavouritesPage = ({ data: { allFavouritesJson: { edges: data } } }) => {
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true)
        }, 16)
        return () => {
            setLoaded(false)
            clearTimeout(timer)
        }
    }, [])
    return <Layout>
        <SEO title="Home" />
        <div className="grid-container" id="favourites">
            <Section title="Books" icon="📕" list={data.filter(({ node }) => node.type === "Books")} loaded={loaded} linkIcon={FaGoodreads} />
            <Section
                title="TV and Movies"
                icon="📺"
                list={data.filter(({ node }) => node.type === "Video")}
                loaded={loaded}
                linkIcon={FaImdb}
            />
            <Section title="Video Games" icon="🎮" list={data.filter(({ node }) => node.type === "Games")} loaded={loaded} linkIcon={FaGlobe} />
        </div>
    </Layout>
};

const Section = ({ title, icon, list, loaded, linkIcon }) => {
    return <div className={`section grid-x grid-margin-x grid-margin-y ${title.toLowerCase()}`}>
        <div className="cell small-12">
            <h3><span role="img">{icon}</span>&nbsp;{title}</h3>
        </div>
        {list.map(({ node }) => {
            return <FavouriteCard loaded={loaded} key={node.id} {...node} LinkIcon={linkIcon} />
        })}
    </div>
}

const FavouriteCard = ({ title, image, description, link, LinkIcon, loaded, type }) => {
    const className = `container paper cell small-12 medium-6 large-4 xxlarge-3  ${loaded ? "loaded" : ""}`
    return <div className={className}>
        {image && <Img fluid={image.childImageSharp.fluid} />}
        <div className="content">
            <h5>{title}</h5>
            <p>{description}</p>
            {link &&
                <a href={link} rel="noreferrer" target="_blank">
                    <LinkIcon />
                </a>
            }
        </div>
    </div>
}

export const query = graphql`
query favourites {
  allFavouritesJson {
    edges {
      node {
        title
        type
        description
        link
        id
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`;

export default FavouritesPage;